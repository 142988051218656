import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useSiteMetadata } from '../hooks/use-site-metadata';

interface PropTypes {
  description?: string;
  lang?: string;
  meta?: {
    name: string;
    content: string;
  }[];
  title?: string;
}

const Seo = ({
  description = '',
  lang = 'en',
  meta = [],
  title,
}: PropTypes) => {
  const siteMetadata = useSiteMetadata();

  const metaDescription = description || siteMetadata.description;
  const siteTitle = siteMetadata.title;

  const allMeta = [
    ...meta,
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:title`,
      content: title,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:creator`,
      content: siteMetadata.social.twitter || ``,
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
  ];

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title ? `${title} - ${siteTitle}` : siteTitle}
      meta={allMeta}
    />
  );
};

export default Seo;
