import * as React from 'react';

interface PropTypes {
  label: string;
  unicode: string;
}

const Emoji = ({ label, unicode }: PropTypes) => {
  return (
    <span role="img" aria-label={label}>
      {unicode}
    </span>
  );
};

export default Emoji;
