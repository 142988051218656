import * as React from 'react';
import { Link } from 'gatsby';

import { SiteTheme } from '../types';
import ThemeSwitcher from './theme-switch';
import { useSiteMetadata } from '../hooks/use-site-metadata';

type PropTypes = {
  location: Location;
  page?: {
    title: string;
    path: string;
  };
  children: any;
};

const Layout: React.FC<PropTypes> = ({ location, page, children }) => {
  const siteMetadata = useSiteMetadata();
  const { twitter, github } = siteMetadata.social;
  const rootPath = `${__PATH_PREFIX__}/`;
  const isRootPath = location.pathname === rootPath;
  let header;
  const isClient = typeof window !== 'undefined';

  const [theme, setTheme] = React.useState<SiteTheme>(
    isClient && localStorage.getItem('theme') === 'light' ? 'light' : 'dark',
  );

  React.useEffect(() => {
    document.body.setAttribute('data-theme', theme);
    localStorage.setItem('theme', theme);
  }, [theme]);

  const toggleTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light');
  };

  if (isRootPath) {
    header = (
      <h1 className="main-heading">
        <Link to="/">{siteMetadata.title}</Link>
      </h1>
    );
  } else {
    header = (
      <div>
        <Link className="header-link-home" to="/">
          {siteMetadata.title}
        </Link>
        {page && (
          <>
            <span>{` / `}</span>
            <Link className="header-link-home" to={page.path || '/'}>
              {page.title}
            </Link>
          </>
        )}
      </div>
    );
  }

  return (
    <div className="global-wrapper" data-is-root-path={isRootPath}>
      <header className="global-header">
        <div className="header-left">{header}</div>
        <div className="header-right">
          <ThemeSwitcher clickHandler={toggleTheme} currentTheme={theme} />
        </div>
      </header>
      <main>{children}</main>
      <footer>
        <div>
          <a href={`https://twitter.com/${twitter}`}>twitter</a>
          {` . `}
          <a href={`https://github.com/${github}`}>github</a>
        </div>
        <div>
          Built with <a href="https://www.gatsbyjs.com">Gatsby</a>
          {` . `}
          Color palette <a href="https://github.com/morhetz/gruvbox">Gruvbox</a>
        </div>
      </footer>
    </div>
  );
};

export default Layout;
