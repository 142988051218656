import * as React from 'react';

import Emoji from './emoji';

interface PropTypes {
  clickHandler: React.MouseEventHandler<HTMLButtonElement>;
  currentTheme: 'light' | 'dark';
}

const buttonStyle = {
  background: 'none',
  border: 'none',
  cursor: 'pointer',
  fontSize: 'var(--fontSize-2)',
};

const ThemeSwitcher = ({ clickHandler, currentTheme }: PropTypes) => {
  const emoji =
    currentTheme === 'dark' ? (
      <Emoji label="sun" unicode="🌞" />
    ) : (
      <Emoji label="moon" unicode="🌙" />
    );
  return (
    <button style={buttonStyle} onClick={clickHandler} title={currentTheme}>
      {emoji}
    </button>
  );
};

export default ThemeSwitcher;
